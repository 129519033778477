import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'reactstrap';

import './Navigation.css'

const Navigation = () => {
    const navStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        listStyleType: 'none',
        padding: '20px',
    };

    const linkStyle = {
        padding: '0 10px', // Adjust the padding as needed
    };

    return ( 
        <Navbar bg = "light"  class = "my_class" >
        	<Nav className = "ml-auto" style={navStyle} class="my_class">

                <NavLink to = "/about"  class = "my_link" style={linkStyle}> About </NavLink>
                <NavLink to = "/research"  class = "my_link" style={linkStyle}> Research+Publications </NavLink>
                <NavLink to = "/teaching"  class = "my_link" style={linkStyle} > Teaching+Outreach </NavLink>
                <NavLink to = "/cv"  class = "my_link" style={linkStyle}> CV </NavLink>
                
                {/* Add more NavLinks as needed */}
            </Nav> 
        </Navbar>
    );
}

export default Navigation;
