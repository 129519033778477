import React,{useState} from 'react';
import './About.css'
import stan from './stan.jpg';

const Home = () => {
    return(
        <div>

            <div class="page-container">
                Home page of <h1> Stan Srednyak </h1>

            <div className="container">
            <div className="text-content">
            <p>    
                I am a mathematical physicist working on mathematical methods for particle and nuclear physics and quantum field theory. I am currently an <a href="https://scholars.duke.edu/person/stanislav.srednyak"> adjunct professor </a> at Duke University, Physics Department. I earned PhD from State University of New York at Stony Brook in 2014. 
            </p>
            <p>
                I work mainly on analytic models for scattering amplitudes, collider phenomenology, hadronic physics. I use and develop a variety of mathematical tools from areas ranging from number theory to function theory of several complex variables.
            </p>
            </div>
            <img src={stan} alt="Description" className="image" />            
            </div>

            <p>
                My work is used by physics collaborations: 
            </p>
            <p>
            <ul>
                <li>
                    Jefferson National Laboratory particle physics program: radiative corrections to scattering experiments, structure of hadrons  
                </li>
                <li>
                    Brookhaven National Laboratory program:  Electron Ion Collider theory program, mathematical methods for hadronic physics
                </li>
                <li>
                    Muon g-2 experimental program: analytic models for hadronic light by light contribution to the muon g-2
                </li>
                <li>
                    Amplitudes research program that probes into the analytic structure of amplitudes in quantum field theory and related math.
                </li>
            </ul>
            </p>
            <p>
                In the past I worked on neural networks theory applied to modeling of visual cortex dynamics. I devote part of my research time to applications of neural networks to automated reasoning tasks, in particular to the data coming from automated theorem provers. In particular, I probe the architectures beyond the canonical transformer model.
            </p>
            <p>
                I also work on the structure of bound states in quantum mechanics, focusing on rigorous mathematical methods. I bring tools from spectral theory, function theory, dynamical systems and number theory to this problem. Part of this research has applications to quantum computing.
            </p>
                
            
            
            </div>
        </div>
    )

}



 
export default Home;
