import './App.css';

import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch, Redirect, Routes, useLocation } from 'react-router-dom';


import About from './components/About.js';
import Navigation from './components/Navigation.js';
import Research from "./components/Research.js"
import Teaching from "./components/Teaching.js"


import PdfViewer from './components/PdfViewer'; 

const TITLE = 'Srednyak'


function App() {
  return (
    <div className="MainPage">
      <Helmet >
        <title> { TITLE } </title> 
      </Helmet >

      
      <BrowserRouter >
        <div>
          <Navigation style = {{ color: 'black' }}/> 
          <Routes >
            <Route path="/" element={<About />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/research" element={<Research />}></Route>
            <Route path="/teaching" element={<Teaching />}></Route>
            <Route path="/cv" element={<PdfViewer />} />

          </Routes> 
        </div> 
      </BrowserRouter> 

    </div>
  );
}

export default App;
