const Math = () =>{
        return(
            <div class="page-container">
    
                <h1>Teaching and Outreach</h1>
    
                <p>
                I am a great enthusiast of science and I maintaing the highest standards in teaching at graduate level. 
                </p>
                <p>
                The problems in modern particle physics require a significant mathematical background. I observed that most of the physics curriculum fails short of providing the necessary mathematics courses. Through the work on perturbative amplitudes, it became increasingly clear that tools from algebraic geometry, number theory, function theory play significant roles. In the work on bound state structure in quantum field theory the role of advanced function theory of complex variables became manifest. This last part includes novel chapters of function theory such as resurgence, dynamics of holomorphic functions. In addition, the spectral problems bear essentially on KAM methods, renormalization methods in spectral problems of discrete Schrodinger operators, and related number theoretic ideas. 
                </p>
                <p>
                Therefore I set it as my immediate goal in teaching physics to provide education in these areas of mathematics, so that the future generations of particle physicists can leverage this knowledge in their work on particle physics problems.
                </p>
    
            </div>
        )
    }
    
    
    export default Math;