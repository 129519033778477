const Research = () =>{
        return(
            <div class="page-container">
                <h1>Research and Publications:</h1>
                <p>
                Over the years I consistently pursued the development of mathematical methods for quantum field theory and particle physics. Either fortunately or unfortunately this proved to entail rather broad knowledge of mathematics. To make things more intriguing, some aspects of QFT required math that is not very fashionable or mainstream in the math community, either because of its difficulty or niche character. I will touch on only two aspects of this work.
                </p>
                <p>
                Most collider phenomenology relies on perturbation theory. Perturbative amplitudes are mathematically well defined and provide a fascinating point of entry into QFT. This is witnessed by the modern amplitudes program that is taking steam in several lead institutions in the last few years, and where I have been an active contributor. From a mathematical point of view, amplitudes are periods of holomorphic top forms with logarithmic singularities. In that sense, they occupy a small corner in the development of the general theory of periods, providing examples where explicit calculations are often possible. My work focused on development of the theory of hyperfunctions in this context. I worked on algorithms to compute the Gauss Manin connections for the functions in question and on the applications of methods from algebraic geometry, including toric geometry, generalized hypergeometric functions in the sense of Gelfand, Kapranov and Zelevinsky, geometric invariant theory. More recently, I shifted towards the study of isomonodromy, irregular singularities, and quantum groups in the context of the above problem.
                </p>
                <p>
                The second aspect of my work concerns the development of the theory of functions of one and several complex variables for the problems in QFT. It is known that nonperturbative physics requires drastic expansion of the set of functions among which to seek solutions of the corresponding scattering and spectral problems. As an elementary example, one may consider the Coulomb spectral problem in non relativistic quantum mechanics. This problem entails the quantum version of KAM theory. This problem has led me deep into the study of functional methods, including Diophantine geometry, summation of lacunary series, resurgence. One of the fascinating aspects of this study is that the relatively modest setup leads to very complicated functional forms that seem to be novel from the point of view of modern math literature. In particular, I developed the theory of integral equations with algebraic kernels and moving singularities. This theory naturally generalized the theory hypergeometric functions by allowing more variety of branching behaviours. I observed bifurcation phenomena in the singularity sets of solutions that are sensitive to the Diophantine nature of the parameters of the problem. 
                </p> 
    
                <p>
                    <ul>
                        <li> S.Srednyak, I. Akushevich, M.Kovtun, "Neural background of Alzheimer's Disease", in preparation. </li>
    
                        <li> K.Mohan, U. Preira-Obilinovic, S. Srednyak, Y. Amit, N.Brunel, D. Freedman, "Visual familiarity learning at multiple timescales in the primate inferotemporal cortex", in preparation </li>
    
    
                        <li> I.Akushevich, A.Ilyichev,S.Srednyak,"Exact and Leading Order Radiative Effects in Semi-inclusive Deep Inelastic Scattering",<a href = "https://arxiv.org/abs/2403.18029">[2403.18029 [hep-ph]] </a></li>
    
                        <li> I. Akushevich, H. Avakian, S. Ilyichev, S. Srednyak, "Complete lowest order radiative corrections in semi-inclusive scattering of polarized particles",  Eur.Phys.J.A 59 (2023) 246,<a href = "https://arxiv.org/abs/2310.17961"> [2310.17961 [hep-ph]] </a></li>
    
                        <li> S. Srednyak and V. Khachatryan, Vanishing cycles and analysis of singularities of Feynman diagrams, (2023), <a href = "https://arxiv.org/abs/2307.11302">[arXiv: 2307.11302 [math-ph]] </a> </li>
    
                        <li> J. Zhou et al., Lowest-order QED radiative corrections in unpolarized elastic electron-deuteron
         scattering beyond the ultra-relativistic limit for the proposed deuteron charge radius measurement at Jefferson Laboratory, (2023), Eur.Phys.J.A 59 (2023) 11, 256, <a href="https://arxiv.org/abs/2307.09680"> [2307.09680 [nucl-th]] </a> </li>
    
    
                        <li> R. Abir et al., The case for an EIC Theory Alliance: Theoretical Challenges of the EIC, (2023), <a href ="https://arxiv.org/abs/2305.14572">[arXiv: 2305.14572 [hep-ph]] </a> </li>
    
    
                        <li> P. Achenbach et al., The Present and Future of QCD, (2023), <a  href="https://arxiv.org/abs/2303.02579"> [arXiv: 2303.02579 [hep-ph]]</a> </li>
    
                        <li> S. Srednyak, Gauss-Manin equations for propagators in the case of arbitrary masses, (2023),<a href="https://arxiv.org/abs/2301.02685"> [arXiv:2301.02685[hep-th]] </a> </li>
    
                        <li> S. Srednyak, Structure of vertices in massless theories, (2022), <a href="https://arxiv.org/abs/2212.10353"> [arXiv: 2212.10353 [hep-th]] </a> </li>
    
                        <li> D. Byer et al., SIDIS-RC EvGen: A Monte-Carlo event generator of semi-inclusive deep inelastic scattering with the lowest-order QED radiative corrections, Comput.Phys.Commun. 287 (2023) 108702, <a href="https://arxiv.org/abs/2210.03785" > [2210.03785 [hep-ph]] </a> </li>
    
    
                        <li>  A. Afanasev et al., CFNS Ad-Hoc meeting on Radiative Corrections Whitepaper, (2020), <a href="https://arxiv.org/abs/2012.09970">  [arXiv:2012.09970 [nucl-th]] </a> </li>
    
                        <li>  A. Gasparian et al., PRad-II: A New Upgraded High Precision Measurement of the Proton Charge Radius, (2020), <a href = "https://arxiv.org/abs/2009.10510"> [arXiv: 2009.10510 [nucl-ex]] </a> </li>
    
                        <li> S. Srednyak, Properties of solutions of the “naive” functional Schroedinger equation for QCD, (2018), <a href ="https://arxiv.org/abs/1806.00657"> [arXiv:1806.00657 [hep-th]] </a></li>
    
                        <li>  S. Srednyak, Bound state equations in Riemannian geometry, (2018), <a href ="https://arxiv.org/abs/1804.11306"> [arXiv: 1804.11306 [hep-th]] </a> </li>
    
                        <li>  S. Srednyak, Universal deformation of particle momenta space in perturbation theory, (2018), <a href = "https://arxiv.org/abs/1805.00433"> [arXiv: 1805.00433[hep-th]] </a> </li>
    
                        <li> S. Srednyak, Feynman integrals as flat bundles over the complement of Landau varieties, (2017), <a href="https://arxiv.org/abs/1710.09883"> [arXiv:1710.09883 [math-ph]] </a></li>
    
                        <li>  S. Srednyak, Reduction of Feynman integrals to integrals of Schläfli functions, (2017), <a href = "https://arxiv.org/abs/1707.09103"> [arXiv:1707.09103 [math-ph]] </a></li>
    
                        <li>  S. Srednyak, <a href ="https://inspirehep.net/files/f5d4bc3eefb1828981f052cedff46e30"> Aspects of Perturbative Quantum Field Theory </a>, Ph.D. Thesis, The State University of New York at Stony Brook, (2014) </li>
    
                        <li>  S. Srednyak and G. Sterman, Perturbation theory in (2, 2) signature, Physical Review D 87 (2013), no. 10, 105017, <a href = "https://arxiv.org/abs/1302.4290"> [arXiv: 1302.4290 [hep-th]] </a> </li>
    
                        <li>  S. Srednyak, Partial fractioning reduction of perturbative amplitudes, (2011), <a href = "https://arxiv.org/abs/1112.5648">[arXiv: 1112.5648 [hep-th]] </a> </li>
    
                        <li>  T. Lappi, S. Srednyak and R. Venugopalan, Non-perturbative computation of double inclusive gluon production in the Glasma, Journal of High Energy Physics 01 (2010) 066, <a href = "https://arxiv.org/abs/0911.2068"> [arXiv: 0911.2068v2 [hep-ph]] </a></li>
    
                    </ul>
                </p>
    
    
            </div>
        )
    }
    
    export default Research;
    
    
    