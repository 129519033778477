import React from 'react';
import pdfFile from '../cv.pdf'; // Adjust the path accordingly

const PdfViewer = () => {
  return (
    <div>
      <h2>PDF Document</h2>
      {/* Option 1: Embed PDF */}
      <iframe src={pdfFile} width="100%" height="600px" />
      
      {/* Option 2: Direct Link */}
      {/* <a href={pdfFile} target="_blank" rel="noopener noreferrer">Open PDF</a> */}
    </div>
  );
};

export default PdfViewer;
